<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('grade_conversion_table_title')"
                        :isNewButton="checkPermission('gradeconversion_store')"
                        :isColumns="true"
                        @new-button-click="$router.push('/grade/conversions/define')"
                        @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('grade_conversion_table_title')"
                              :isNewButton="checkPermission('gradeconversion_store')"
                              :isColumns="true"
                              @new-button-click="$router.push('/grade/conversions/define')"
                              @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                              :exportExcel="checkPermission('gradeconversiongrade_excelexport')"
                              @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="6" md="4" lg="3" xl="2">
                        <b-form-group :label="$t('id')">
                            <b-form-input type="number" size="sm"
                                          v-model="datatable.queryParams.filter.id"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="5" xl="4">
                        <b-form-group :label="$t('grade_conversion_name')">
                            <grade-conversions-selectbox
                                v-model="datatable.queryParams.filter.grade_conversion_id"></grade-conversions-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal size="lg" ref="editModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('grade_conversion_update_title') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <update-form :id="selectedId" :grade_conversion_id="selectedGradeConversionId" @hide="closeEditModal"></update-form>
                    </div>
                </template>
            </CommonModal>

            <CommonModal size="lg" id="editGradeConversionModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('grade_conversion_update_title') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <update-grade-conversion-form :id="editGradeConversionId" @hide="closeEditModal"></update-grade-conversion-form>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    //Template
    import AppLayout from "@/layouts/AppLayout";
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    //Component
    import Datatable from "@/components/datatable/Datatable";
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import GradeConversionsSelectbox from "@/components/interactive-fields/GradeConversionsSelectbox";
    import CommonModal from "@/components/elements/CommonModal";
    //Other
    import qs from "qs";
    //Service
    import GradeService from "@/services/GradeService";
    //Page
    import UpdateForm from "./updateForm";
    import UpdateGradeConversionForm from "@/modules/gradeConversions/pages/updateGradeConversionForm";
    import RegistrationService from "@/services/RegistrationService";

    export default {
        components: {
            UpdateGradeConversionForm,
            AppLayout,
            Header,
            HeaderMobile,

            Datatable,
            DatatableFilter,
            GradeConversionsSelectbox,
            CommonModal,

            UpdateForm
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-pencil-fill',
                                    permission: "gradeconversion_update",
                                    callback: (row) => {
                                        this.selectedId = row.id
                                        this.$refs.editModal.$refs.commonModal.show()
                                    }
                                },
                                {
                                    text: this.$t('add'),
                                    class: 'ri-add-line',
                                    permission: "gradeconversion_store",
                                    callback: (row) => {
                                        this.selectedId = 0
                                        this.selectedGradeConversionId = row.grade_conversion_id
                                        this.$refs.editModal.$refs.commonModal.show()
                                    }
                                },
                                {
                                    text: this.$t('edit_grade_conversion'),
                                    class: 'ri-pencil-line',
                                    permission: "gradeconversion_update",
                                    callback: (row) => {
                                        this.editGradeConversionId = row.grade_conversion_id
                                        this.$bvModal.show('editGradeConversionModal');
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line',
                                    permission: "gradeconversion_delete",
                                    callback: (row) => {
                                        this.deleteGradeConversionCell(row)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('id'),
                            field: 'id',
                            hidden: false,
                        },
                        {
                            label: this.$t('grade_conversion_name'),
                            field: 'grade_conversion_name',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('count_number'),
                            field: 'id',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('grade_conversion_range_start'),
                            field: 'from_grade',
                            hidden: false,
                        },
                        {
                            label: this.$t('grade_conversion_range_end'),
                            field: 'to_grade',
                            hidden: false,
                        },
                        {
                            label: this.$t('bau_grades'),
                            field: 'grade',
                            hidden: false,
                            sortable: false
                        },
                        {
                            label: this.$t('grade_conversion_id'),
                            field: 'grade_conversion_id',
                            hidden: true,
                            sortable: false
                        }

                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: '-grade_conversion_id',
                        page: 1,
                        limit: 20
                    }
                },
                selectedId: null,
                selectedGradeConversionId: null,
                editGradeConversionId: null
            }
        },
        metaInfo() {
            return {
                title: this.$t("grade_conversion_table_title")
            }
        },
        methods: {
            filter() {
                this.getRows();
            },

            filterClear() {
                this.filterSet()
                this.getRows();
            },

            filterSet() {
                this.datatable.queryParams.filter = {
                    grade_conversion_id: null
                };
            },

            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },

            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },

            changeColumn(key) {
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
            },

            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },

            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return GradeService.getGradeConversionGrades(config)
                                   .then((response) => {
                                       this.datatable.rows = response.data.data
                                       this.datatable.total = response.data.pagination.total
                                   }).finally(() => {
                        this.datatable.isLoading = false;
                    });
            },

            deleteGradeConversionCell(row) {
                GradeService.deleteGradeConversionGrades(row.id).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.getRows()
                }).catch(e => {
                    this.$toast.error(this.$t('api.' + e.data.message));
                })
            },

            closeEditModal() {
                this.$refs.editModal.$refs.commonModal.hide()
                this.$bvModal.hide('editGradeConversionModal');
                this.getRows()
            },

            exportExcel() {
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, { encode: false })
                };

                return GradeService.exportExcel(config)
                    .then((response) => {
                        this._downloadFile(response, 'gradeCoversion.xlsx')
                    })
            },
        },

        created() {
            this.filterSet()
            this.getRows()
        },
    };
</script>
